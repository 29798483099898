import React from 'react';
import '../../App.css';
import HeroSection from '../HeroSection';
import './Home.css';
import Footer from './Footer';
import { FaGem, FaMedal } from 'react-icons/fa';


function Home(){
    return(
        <>
           <div className="home-container">
                <HeroSection src='/images/medicine2.png'></HeroSection>
                <div className="home-content">
                 <div className="introduction">
                    <div className="section">
                        We are an ASIC registered firm based in Adelaide, South Australia, offering our 
                        services to international device and IVD manufacturers aspiring to launch their 
                        products in Australian. We support the process by sponsoring the product as 
                        mandated by Therapeutic Goods Act 1989, Therapeutic Goods Regulations 1990 and 
                        Therapeutic Goods (medical devices) Regulations 2002 and by liaising the regulatory 
                        agencies to list the products into Australian Register of Therapeutic Goods.  
                    </div>
                    <div className="section">
                        Pre commercialization we support to register the goods with the TGA and assist to during TGA audits. 
                        Our services continue though the registration process to post market compliance up to the end of 
                        the product life in Australia. We support the manufacturing companies meet the updated 
                        Regulatory requirements, by maintaining the technical documents as recommended by the Agency.  
                    </div>
                    
                    
                    </div>
                </div>

                <section className="experience">
                    <div className="grid-container">
                        <div className="title-heading">
                            <h3>Specialists in</h3>
                            <div className="icon-container">
                                <FaMedal className="medal"></FaMedal>
                            </div>
                            
                            <h1>TGA Sponsorship</h1>
                            <p> 
                                The sponsor is responsible for applying to the TGA to have their 
                                therapeutic good included on the Australian Register of Therapeutic Goods (ARTG). 
                                In Australia, therapeutic goods must be included on the ARTG before they can be sold. 
                                The sponsor must be a resident of Australia or be an incorporated body in Australia and 
                                conducting business in Australia where the representative of the company is residing in Australia. 
                            </p>

                            <div className="para">
                                <p>
                                    RGN Medical is a registered entity based in Adelaide, South Australia and 
                                    fulfils all the necessary requirements to qualify as a sponsor for overseas 
                                    medical device manufacturers 
                                </p>
                            
                            </div>
                        </div>

                        <div className="title-heading">
                        <div className="icon-container"><FaMedal className="medal"></FaMedal></div>
                            <h1>Training and Consultancy</h1>
                            <p> 
                                Employees are the biggest asset for any organization. 
                                Keeping the skill and knowledge at optimal levels is essential to 
                                maintain an edge in the industry. With rapidly evolving regulatory 
                                requirements and new industry standards, it is often challenging to 
                                keep up with the requirements.
                            </p>

                            <div className="para">
                                <p>
                                    RGN Medical provide a wide range of services to meet the individual 
                                    organization needs. From providing pre regulatory audit supports to  
                                    QMS system development, we are always with you on this journey. 
                                    With years of experience in regulatory industry (Pharmaceutical and 
                                    Medical Devices) we endeavour to bring the best and most efficient 
                                    Quality Management System to you.  
                                </p>
                            
                            </div>
                        </div>
                    </div>

                </section>
                

                <div className="blog-container">
                    <h2>Our Values</h2>
                    
                    <div className="activities-grid">
                    <div className="activities-grid-item trust">
                        <FaGem className="gem"></FaGem>
                        <h1>TRUST</h1>
                        <p>
                            RGN medical aspire to the trusted business partner by acting 
                            as a “Sponsor” for overseas manufacturers to liaise with the Therapeutic Goods 
                            Administration (TGA) on all matters relating to the provision of Medical Devices 
                            in Australia and ensure product registration with ATGR. 
                            We understand the confidentiality of commercial operation and act with 
                            integrity and ethics while handling sensitive information. 
                            RGN has robust procedures in place to ensure data security both in electronic 
                            and physical form.
                        </p>
                    </div>
                    <div className="activities-grid-item excel">
                        <FaGem className="gem"></FaGem>
                        <h1>EXCELLENCE</h1>
                        <p>
                            With years of industrial experience, we have gained extensive knowledge 
                            in global regulatory requirements across a range of device classifications for 
                            CE Marking (European Union), Food and Drug Administration (FDA, United States), 
                            Health Canada and TGA (Australia). With hands-on experience across the entire medical 
                            device life cycle including design and development, risk management, manufacturing support 
                            and post market surveillance our practical industry experience provides clients with 
                            regulatory solutions focused on compliance while meeting individual commercially focused needs.
                        </p>
                    </div>
                    <div className="activities-grid-item collab">
                        <FaGem className="gem"></FaGem>
                        <h1>COLLABORATION</h1>
                        <p>
                            To maintain control over device and IVD registration, 
                            it is generally recommended for overseas manufacturers to appoint 
                            a "third party" Sponsor who is not commercially involved in the sale 
                            of the products and acts solely as regulatory representative in Australia. 
                            RGN medical not only provide Sponsorship services at the most 
                            competitive price, but it also provides you with necessary regulatory 
                            intelligence to make informed commercial decisions.
                        </p>
                    </div>
                </div>
                    
                </div>
                <Footer></Footer>
           </div>
           
        </>
    )
}

export default Home;