import './App.css';
import Navbar from './components/Navbar';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Home from './components/pages/Home';
import AboutUs from './components/pages/AboutUs';
import Services from './components/pages/Services';
import News from './components/pages/News';
import ContactUs from './components/pages/ContactUs';
import PrivacyPolicy from './components/pages/PrivacyPolicy';
import RegulatoryUpdates from './components/pages/RegulatoryUpdates';

function App() {
  return (
    <>
      <Router>
          <Navbar/>
          
          <Switch>
            <Route path='/' exact component={Home}/>
            <Route path='/about-us' component={AboutUs}/>
            <Route path='/news' component={News}/>
            <Route path='/services' component={Services}/>
            <Route path='/contact-us' component={ContactUs}/>
            <Route path='/privacypolicy' component={PrivacyPolicy}/>
            <Route path='/regulatory-updates' component={RegulatoryUpdates}/>
          </Switch>
      </Router>
      
    </>
  );
}

export default App;
