import React, {useState} from 'react'
import { FaAngleDoubleDown, FaAngleDoubleUp, FaNewspaper} from 'react-icons/fa'
import ArticleDetails from './ArticleDetails';
import './NewsArticle.css'

function NewsArticle(props) {

    const [show, showHide] = useState(true);

    function ShowHide(id){
        var element = document.getElementById(id)
        console.log("show-hide");
        
        if(element == null)
        {
            console.log("Element Not Found!");
            return;
        }
        console.log(show);
        showHide(!show);
        
        if (show) {
            element.style.display = "block";
        } else {
            element.style.display = "none";
        }
    }

    var element;
    if(show){
        element = <FaAngleDoubleDown className="show-hide" id="1" onClick={() => ShowHide("news-content_"+ props.id)}></FaAngleDoubleDown>
    }
    else{
        element = <FaAngleDoubleUp className="show-hide" id="2" onClick={()=> ShowHide("news-content_"+ props.id)}></FaAngleDoubleUp>
    }

    return (
        <div className="news-article">
            
            <div className="news-heading"><div className="icon-container"><FaNewspaper className="news-icon"></FaNewspaper></div><h3>{props.heading}</h3><div className="dropdown-container">{element}</div></div>
            
            <div className="details-container"><ArticleDetails author="admin" date={props.date}></ArticleDetails></div>
            <div className="news-content" id={"news-content_" + props.id}>
                <div className="description">{props.description}</div>
                <div>{props.newsContent}</div>
            </div>
            
        </div>
    )
}

export default NewsArticle
