import React from 'react';
import '../App.css';
import './HeroSectionA.css';

function HeroSectionA(props) {
    return (
        <div className="hero-container-a">
            <div className="hero-content-a">              
            </div>
            
        </div>
    )
}

export default HeroSectionA
