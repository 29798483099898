import React from 'react';
import {FaBars, FaTimes } from 'react-icons/fa'

function Hamburger({isClicked, cName}){
    let markup;
    if(isClicked)
    {
        markup = <FaTimes className = {cName}></FaTimes>
    }
    else{
        markup = <FaBars className = {cName}></FaBars>
    }
    return markup
}

export default Hamburger;

