import React from 'react'
import './PrivacyPolicy.css'
import './Home.css'
import HeroSection from '../HeroSection'
import Footer from './Footer'

function PrivacyPolicy() {
    return (
        <>
            <div className="policy-container">
                <HeroSection src='/images/medicine2.png'></HeroSection>
                <div className="policy-content">
                    <div className="introduction2">
                        <h1>Our Privacy Policy</h1>
                        <section>
                            This Privacy Policy describes how RGN Medical and its parent company RGN Investments Pty Ltd use and disclose the information 
                            we collect from you through our website, social media forums, and other online services and the choices you have about how we use your information. 
                        </section>
                        
                        <section>
                            <h3>What information do we collect?</h3>
                            <div className="list">
                                <ul>
                                    <li className="item">
                                        Information you provide: We collect information that you voluntarily provide when you use the Services, 
                                        such as when you “contact” us with a question, comment, or request. Currently RGN Medical store the contact person name, 
                                        contact details such as email ID and phone number for future communications including , but not limited to, providing business reply, 
                                        follow up communication and generic or personalised marketing emails.
                                    </li>
                                    <li className="item">
                                        RGN Medical DO NOT request or store per personal information like residential address, age, birth date, gender, photo, 
                                        social media account ID, post code, education, demographic information, information about your health and/or medical conditions, 
                                        product usage and preferences. 
                                    </li>
                                    <li className="item">
                                        As of date RGN Medical DO NOT  engage any third party services to collect any data either from public domain or through investigational means.
                                    </li>
                                </ul>
                            </div>
                        </section> 

                        <section>
                            <h3>How do we use your information?</h3>
                            <div className="list">
                                <ul>
                                    <li className="item">to respond to your queries/questions.</li>
                                    <li className="item">to fulfil purchases or other transactions made by you;</li>
                                    <li className="item">to deliver marketing communications that may be of interest to you;</li>
                                    <li className="item">to send administrative information to you, such as information about the Services and our terms, conditions and policies;</li>
                                    <li className="item">as we believe to be necessary or appropriate: 
                                        <div className="list-container">
                                        <ol>
                                            <li>
                                                under applicable law, including laws outside your country of residence;
                                            </li>
                                            <li>to comply with legal process;</li>
                                            <li>to respond to requests from government or law enforcement authorities, including authorities outside your country of residence;</li>
                                            <li>to enforce our terms and conditions;</li>
                                            <li>to protect our operations;</li>
                                            <li>to protect our rights, privacy, safety or property, and/or that of you or others; and</li>
                                            <li>to allow us to pursue available remedies or limit the damages that we may sustain.</li>
                                        </ol>
                                    </div>
                                    </li>
                                </ul>
                                
                            </div>

 
                        </section>
                        <section>
                            <h3>
                                When and to whom do we disclose your information? 
                            </h3>
                            <div className="list">
                                <ul>
                                    <li className="item">
                                        as required by law, such as to law enforcement, to health authorities to 
                                        report possible adverse events, during government inspections or audits, 
                                        as ordered or directed by courts or other governmental agencies, or in order 
                                        to comply with a subpoena or other legal process;
                                    </li>
                                    <li className="item">
                                        when we believe in good faith that disclosure is necessary 
                                        to protect legal rights or the security or integrity of our 
                                        operations or the Services; protect your safety or the safety of others; investigate fraud, a breach of contract, or a violation of law; respond to a government request; or allow us to pursue available remedies or limit the damages that we may sustain; and 
                                    </li>
                                    
                                </ul>
                            </div>
                        </section>

                        <section>
                            <h3>Your choices</h3>
                            <p>
                                Where you have elected to participate in one of our programs or 
                                services or to receive marketing communications from us, we offer you the ability to discontinue your participation or to 
                                opt out of receiving those communications in the communication itself. 
                                Alternatively, you can contact us at <span className="email">info@rgnmedical.com.au</span> to opt out  

                            </p>
                        </section>

                        <section>
                            <h3>Information security</h3>
                            <p>
                                We use appropriate technical, administrative, and physical 
                                safeguards to protect the information collected through the 
                                Services. Unfortunately, no organisation can guarantee the 
                                absolute security of electronic information, especially 
                                information transmitted over the Internet.
                            </p>
                        </section>

                        <section>
                            <h3>
                                Children's information
                            </h3>
                            <p>
                                Our services are not directed to children under the age of 
                                eighteen (18), and we do not knowingly collect personal 
                                information from such children.  
                            </p>
                        </section>
                        <section>
                            <h3>Changes to this privacy policy</h3>
                            <p>
                                We may update this Privacy Policy from time to time by posting 
                                a new Privacy Policy on our website.
                            </p>
                        </section>

                        <section>
                            <h3>How to contact us</h3>
                            <p>
                                If you have any questions about our Privacy Policy, 
                                please contact us by emailing <span className="email">info@rgnmedical.com.au</span> 
                            </p>
                        </section>
                        
                        </div>
                </div>
                <Footer></Footer>
            </div>
        </>
        
    )
}

export default PrivacyPolicy
