import React from 'react';
import '../App.css';
import './HeroSection.css';

function HeroSection(props) {
    return (
        <div className="hero-container">
            {/* <h1>RGN MEDICAL</h1> */}
            <div className="hero-content">
                
            </div>
            
        </div>
    )
}

export default HeroSection
