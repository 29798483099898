import React from 'react'
import './Footer.css';
import { FaFacebook, FaLinkedin, FaInstagram, FaTwitter, FaPhone, FaEnvelope } from 'react-icons/fa'
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <div className="footer">
            <div className="footer-container">

                <div className="about-us">
                    <h2>About Us</h2>
                    <p>
                    We are a medical device consulting company. We provide services to international device and IVD manufacturers to launch products in the Australian market by acting as their TGA Sponsors or Australian Authorized Representative per the Therapeutic Goods Act 1989, Therapeutic Goods Regulations 1990 and Therapeutic Goods (medical devices) Regulations 2002. We are registered in Australia and provide a window of opportunity to explore the Australian market.
                    </p>
                
                    <ul className="social-media">
                        <li>
                            <Link to='/' target='_blank' aria-label='Facebook'>
                                <div>
                                    <FaFacebook className="social-media-icon"></FaFacebook>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to='/' target='_blank' aria-label="Linked In">
                                <div>
                                    <FaLinkedin className="social-media-icon"></FaLinkedin>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to='/' target='_blank' aria-label='Instagram'>
                                <div>
                                    <FaInstagram className="social-media-icon"></FaInstagram>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to='/' target='_blank' aria-label='Twitter'>
                                <div>
                                    <FaTwitter className="social-media-icon"></FaTwitter>
                                </div>
                            </Link>
                        </li>
                    </ul>
                
                
                </div>

                <div className="quick-links">
                    <h2>Quick Links</h2>
                    <ul> 
                        <li><a href="/about-us/#mission">Our Mission</a></li>
                        <li><a href="/about-us/#vision">Our Vision</a></li>
                        <li><a href="/services/#tga">TGA Sponsorship</a></li>
                        <li><a href="/services/#arti">AI Solutions</a></li>
                        <li><a href="/services/#training">Training And Consulting</a></li>
                        <li><a href="/regulatory-updates">Regulatory Updates</a></li>
                        <li><a href="/PrivacyPolicy">Privacy Policy</a></li>
                        <li><Link to='/RegistrationCertificate.pdf' target='_blank' aria-label='Registration Certificate'>Registration Certificate</Link></li>
                        
                    </ul>
                </div>

                <div className="contact">
                    <h2>Contact Info</h2>
                    <ul className="info">
                        <li></li>
                        <li>
                            <FaPhone className="contact-icon"></FaPhone> 
                            <span>+61 452 459 567</span>
                            </li>
                        <li>
                            <FaEnvelope className="contact-icon"></FaEnvelope>
                            <span>info@rgnmedical.com.au</span>
                        </li>
                    </ul>
                   
                </div>
                <div className="copyright">RGN Medical &#169; 2021. All Rights Reserved</div>
                
                
            </div>
            <div className="lic-section">
                    <div></div>
                    
            </div>
            
            
        </div>
    )
}

export default Footer
