import React from 'react'
import './ContactUs.css'
import { FaPhone, FaEnvelope, FaLocationArrow } from 'react-icons/fa'

import HeroSection from '../HeroSection';
import Footer from './Footer';
import emailjs from 'emailjs-com';


function ContactUs() {
    
    function sendEmail(e) {
        e.preventDefault();
        
    
        emailjs.sendForm('service_oldr008', 'template_udibsfl', e.target, 'user_NMsq1MUWbmhWwfGjR1mpi')
          .then((result) => {
              console.log(result.text);
              document.getElementById("from_name").value='';
              document.getElementById("reply_to").value='';
              document.getElementById("message").value='';
              
          }, (error) => {
              console.log(error.text);
          });
      }

    return (
        <div className="contact-us-container">
            <HeroSection src='/images/medicine2.png'></HeroSection>
            <div className="home-content">
        <div className="c-contact">
            <div className="c-content">
                <h2>Connect With Us</h2>
                <p>We are incredibly responsive to your requests and value your questions.
                <br></br>Use the form below to drop us an e-mail.
                </p>
            </div>
            
        
            <div className="c-container">
                <div className="c-contact-info">
                    <div className="box">
                        <div className="icon"><FaLocationArrow></FaLocationArrow></div>
                        <div className="text">
                            <h3>Address</h3>
                            <p>
                                186A Prospect Road, <br></br>Prospect, SA 5000, Australia  
                            </p>
                        </div>
                    </div>
                    <div className="box">
                        <div className="icon"><FaPhone></FaPhone></div>
                        <div className="text">
                            <h3>Phone</h3>
                            <p>
                                +61 452 459 567 
                            </p>
                        </div>
                    </div>
                    <div className="box">
                        <div className="icon"><FaEnvelope></FaEnvelope></div>
                        <div className="text">
                            <h3>Email</h3>
                            <p>
                                info@rgnmedical.com.au
                            </p>
                        </div>
                    </div>
                </div>
                <div className="contactForm">
                    <form onSubmit={sendEmail}>
                        <h2>Send Message</h2>
                        <div className="inputBox">
                            <input type="text" required="required" name="from_name" id="from_name"></input>
                            <span>Full Name</span>
                        </div>
                        <div className="inputBox">
                            <input type="text" required="required" name="reply_to" id="reply_to"></input>
                            <span>Email</span>
                        </div>

                        <div className="inputBox">
                            <textarea required="required" name="message" id="message"></textarea>
                            <span>Type your message here...</span>
                        </div>

                        <div className="inputBox">
                            <input className="submitButton" type="submit" value="Submit"></input>
                        </div>

                    </form>

                </div>
            </div>
            
        </div>
        </div>
        <Footer></Footer>
        </div>
    )
}

export default ContactUs
