import React from 'react'
import { FaUser } from 'react-icons/fa'
import './ArticleDetails.css'

function ArticleDetails(props) {
    return (
        <div className="article-details">
            <FaUser className="author"></FaUser><span>Posted by</span><span className="property">{props.author}</span><span>{props.date}</span>
        </div>
    )
}

export default ArticleDetails
