import React, {useState} from 'react';
import {Link} from 'react-router-dom';
// import { Button } from './Button';

import './Navbar.css';

import Hamburger from './Hamburger';

function Navbar() {
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () =>{setClick(false)};

    const showButton = ()=>{
        
        if(window.innerWidth <= 960){
            setButton(false);
        }
        else{
            setButton(true);
        }
    }

    window.addEventListener('resize', showButton);

    return (
        <div className="navbar-container">
            {/* <div className="wave">
            <div class="custom-shape-divider-top-1627049274">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" class="shape-fill"></path>
                </svg>
            </div>
                
            </div> */}
        <nav className="navbar">
                <Link to='/' className="navbar-logo" onClick={closeMobileMenu}>
                    
                    <img src="/images/Logo.png" className="logo"></img>
                </Link>
                {!button && <div className = "menu-icon" onClick = {handleClick}>
                    <Hamburger cName="hamburger" isClicked = {click}></Hamburger>
                </div>}
                <ul className={click? 'nav-menu active' : 'nav-menu'}>
                    <li className='nav-item'>
                        <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                            HOME
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/services' className='nav-links' onClick={closeMobileMenu}>
                            SERVICES
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/news' className='nav-links' onClick={closeMobileMenu}>
                            NEWS
                        </Link>
                    </li>
                     <li className='nav-item'>
                        <Link to='/about-us' className='nav-links' onClick={closeMobileMenu}>
                            ABOUT US
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/contact-us' className='nav-links contact-btn' onClick={closeMobileMenu}>
                            CONTACT
                        </Link>
                    </li>
                    {/* <li className='nav-item'>
                        <Link to='/contact-us' className='nav-links' onClick={closeMobileMenu}>
                            Contact Us
                        </Link>
                    </li> */}
                    {/* <li className='nav-item'>
                        <Link to='/sign-up' className='nav-links-mobile' onClick={closeMobileMenu}>
                            Sign Up
                        </Link>
                    </li> */}

                   
                </ul>

                {/* {button && <Button buttonStyle='btn--outline'>SIGN UP</Button>} */}

                {/* {!button && } */}

                
                
            
        </nav>
        </div>
    )
}

export default Navbar
