import React from 'react';
import '../../App.css';
import Footer from './Footer';
import HeroSectionA from '../HeroSectionA';
import './AboutUs.css'
import './Home.css'
import { FaGem, FaQuoteLeft } from 'react-icons/fa';

function AboutUs(){
    return(
        <div className="about-us-container">
            <HeroSectionA src='/images/about-us3.png' label="ABOUT US"></HeroSectionA>
            {/* <div className="aWave">
                
                    <div className="custom-shape-divider-top-1624683434">
                        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                            <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
                        </svg>
                    </div>
                </div> */}
            <div className= "about-us-content">
                <div className="sub-section">
                    <p>
                        We are a South Australian based, ASIC registered firm providing regulatory services 
                        to overseas medium and small size global Life sciences companies, (Medical Device| IVD) 
                        in their quest to explore Australian market.
                    </p>
                    <p>
                        With years of industrial experience, we have gained extensive knowledge in stringent global regulatory requirements 
                        across a range of device classifications. These include CE Marking (European Union), 
                        Food and Drug Administration (FDA, United States), Health Canada, and TGA (Australia). 
                        Our hands-on industrial experience across the entire medical device life cycle 
                        including design and development, risk management, manufacturing support, and 
                        post-market surveillance provides clients with regulatory solutions focused on 
                        compliance while meeting commercially focused needs. 
                    </p>

                    <p>
                        <div className="sub-heading">Our services include: </div>
                        <div className="list">
                            <div className="item">
                                Providing TGA sponsorship per Therapeutic Goods Act 1989, Therapeutic Goods 
                                Regulations 1990 and Therapeutic Goods (medical devices) Regulations 2002 
                                to register their products with ARTG.
                            </div>
                            <div className="item">
                                Post commercialization we fulfill all the regulatory obligation of the 
                                TGA Sponsor and act as liaison between the Therapeutic Goods Administration 
                                (TGA) and the foreign manufacturer. 

                            </div>
                            <div className="item">
                                Support the post market surveillance activity. 
                            </div>
                        </div>
                    </p>
                    <p id="vision">
                        We also offer our expertise in the form of Training and consultancy. 
                        With years of regulatory experience, we can support you for Vendor audits, 
                        pre regulatory inspections assessment, Data Integrity compliance and QMS design.
                    </p>
                    
                </div>
                
                <div className="about-us-section">
                    <div className="title-heading">
                        <h1>Our Vision</h1>
                        <div className="icon-container">
                            <FaGem className="diamond"></FaGem>
                        </div>
                        
                        <div className="sub-section quoted-text">
                        <FaQuoteLeft className="quote" id="mission"></FaQuoteLeft>Ensuring the best medical technology is made locally available to help people improve their quality of life.
                    </div>
                    </div>
                   
                     
                </div>

                <div className="about-us-section">
                    <div className="title-heading">
                        <h1>Our Mission</h1>
                        <div className="icon-container">
                            <FaGem className="diamond"></FaGem>
                        </div>
                        
                        <div className="sub-section quoted-text">
                        <FaQuoteLeft className="quote"></FaQuoteLeft> We aim to be a trusted regulatory partner for medical device and IVD manufacturers by providing reliable and quality services to support them bring new and innovative product to market.
                    </div>
                    </div>
                   
                </div>
                <div className="about-us-section">
                    <div className="title-heading">
                        <h1 id="mission">Quality Policy</h1>
                        <div className="icon-container">
                            <FaGem className="diamond"></FaGem>
                        </div>
                        
                        <div className="sub-section quoted-text">
                            <FaQuoteLeft className="quote-small"></FaQuoteLeft> We aim to bring quality medical devices to 
                            Australian market.
                        </div>
                        <div className="sub-section quoted-text">
                            <FaQuoteLeft className="quote-small"></FaQuoteLeft>We operate in the regulatory space and 
                            recognize our obligation to patient safety and regulatory compliance. As a responsible sponsor 
                            for overseas medical device manufacturers, we ensure that the quality and efficacy meet 
                            the regulatory standards before sponsoring the devices and IVDs.
                        </div>
                        <div className="sub-section quoted-text">
                            <FaQuoteLeft className="quote-small"></FaQuoteLeft> We are committed to our business partners and clients, 
                            providing them with most efficient services and regulatory advices to market quality product in Australia. 
                            We will continue to develop our quality system in line with regulatory guidelines and invest in our 
                            human resources and technology to deliver best services to our clients.
                        </div>
                        <div className="sub-section quoted-text">
                            <FaQuoteLeft className="quote-small"></FaQuoteLeft>We will contribute to the industry by supporting organizations develop their 
                            quality systems via our consultancy and training programs. 
                        </div>
                    </div>
                   
                </div>
                
                    {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#a2d9ff" fill-opacity="1" d="M0,224L80,208C160,192,320,160,480,170.7C640,181,800,235,960,224C1120,213,1280,139,1360,101.3L1440,64L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path></svg> */}
            
            </div>
            <div>
            </div>

            
            <Footer></Footer>
         </div>
        
    )
}

export default AboutUs;