import React from 'react'
import '../../App.css';
import HeroSection from '../HeroSection'
import Footer from './Footer'
import './Services.css'
import './Home.css'
import { FaHandsHelping } from 'react-icons/fa';

function Services() {
    return (
        <div className="home-container">
            <HeroSection src='/images/services.png'></HeroSection>
            <div className="summary-container">
                <div className="summary">
                    <div className="heading">
                    Australian TGA Sponsor representation
                    </div>
                        <div className="readMore">
                            <a href="#">Read More</a>
                        </div>
                </div>
                <div className="summary">
                    <div className="heading">Training and QMS Consulting</div>
                    <div className="readMore"><a href="#training">Read More</a></div>
                    
                </div>
                <div className="summary">
                    <div className="heading">
                        Artificial Intelligence Solution
                    </div>
                    <div className="readMore">
                        <a href="#arti">Read More</a>
                    </div>
                </div>
                
            </div>
            <div className= "home-content" id="tga">
                <div className="section card">
                    <div className="title-heading" >
                        <h1>Australian TGA Sponsor representation</h1>
                    </div>
                    <div className = "section-content">
                        Foreign manufacturers wishing to import and sell a medical device in Australia 
                        are required to register the device on the ARTG before selling the products in 
                        Australia. They also need in-country representation – TGA Sponsor 
                        (similar to Authorized Representative requirements in EU or an agent for the US).
                    </div>
                    <div className="section-content" >
                        A TGA Sponsor communicates with TGA, registers the medical device on ARTG, 
                        and acts as a regulatory representative before and during commercialization 
                        in Australia.   
                    </div>
                    <div className="section-content">
                        TGA requires sponsor contact details to be stated on the Instruction for Use (IFU) and package label.
                    </div>
                    
                    <div className="section-content">
                        <h3>How can we help?</h3>
                        <div className="icon-container">
                            <FaHandsHelping className="hands"></FaHandsHelping>
                        </div>
                        
                        <p>
                            We can act as your Australian Sponsor (TGA Sponsor) as required by the 
                            “Australian Therapeutic Goods (Medical Device) Regulations 2002”.
                        </p>
                        <p>
                            As part of the Australian Sponsor services, we provide the following:
                        </p>
                        <div className="list"   id="art-i">
                            <div className="item">
                                Device registration on the ARTG (Australian Register of Therapeutic Goods).
                            </div>
                            <div className="item">
                                Storing your technical file for TGA review upon request.
                            </div>
                            <div className="item">
                                Review of advertising materials, labels, and IFU before use.
                            </div>
                            <div className="item">
                                Represent the company in Australia as required by TGA, including adding MED QMS contact details on the device labels and IFUs.
                            </div>
                            <div className="item">
                                Assisting in reporting adverse events and recalls to TGA
                            </div>
                            
                        </div>
                            
                        
                        <br></br>
                        <p>
                            Additionally, we provide manufacturers with critical intelligence 
                            (market and regulatory) to develop their business strategy.
                            Please contact us for further details.
                        </p>
                    </div>
                </div>
                <div className="section card" id="training">
                    <div className="title-heading">
                        <h1>Training and Consultancy</h1>
                    </div>
                    <div className="section-content">
                        QMS is all about management – providing the rigor and discipline to drive 
                        product quality to meet your customers’ needs. It also includes providing information 
                        that reflects effective management.
                        <br></br>
                        
                    </div>
                    <div className="section-content">
                        Data Integrity compliance is a leading topic for regulators and 
                        regulated industries alike. With companies facing steep penalties for failing to 
                        handle GMP data; Data Integrity is an area most companies want to improve. 
                        With ever-evolving and subjective requirements, it is important to align 
                        Data Integrity with Quality Management System. This translates into a well-executed 
                        system which drives excellence. It bringing value to your business and gives you information 
                        you need to promote continuous improvement and ensure compliance. 
                    </div>
                    
                    <div className="section-content">
                        <h3>How can we help?</h3>
                        <div className="icon-container">
                            <FaHandsHelping className="hands"></FaHandsHelping>
                        </div>
                        
                        <p>We provide to following services to help your QMS </p>
                        <div className="list">
                            <div className="item">Pre-audit assessment</div>
                            <div className="item">Vendor audits</div>
                            <div className="item">On-site QMS training</div>
                            <div className="item">QMS development</div>
                            <div className="item">Data Integrity Audit and Compliance</div>
                        </div>
                    </div>
                </div>
                <div className="section card">
                    <div className="title-heading">
                        <h1 >Artificial Intelligence solution</h1>
                    </div>
                    <div className="section-content">
                        The last few years have seen unprecedented technology-led growth in the 
                        healthcare sector. In more recent times, AI has arrived as a powerful enabler 
                        in the healthcare sector. However, up until now, AI in healthcare has largely been 
                        focused on drug development and physical medical devices.
                    </div>
                    <div className="section-content">
                        Gravitas AI solution provides the healthcare sector a way data is presented to the 
                        employees and clients. Gravitas AI has developed a highly intelligent, universally 
                        integrable single conversational AI platform – <b>“Tina”, Your Tireless AI</b>
                    </div>
                    <div className="section-content" id="arti">
                        Through Tina, manufacturers can automate their contact center, patients can 
                        receive information on diagnosis, ask queries with doctors and, complete
                        tasks such as appointment management. Tina is an exceptionally smart next-generation 
                        bot and is powered by patent-pending algorithms. Due to an innovative design, 
                        her skill sets are easily transferrable to a lot of other sectors outside of healthcare.
                        RGN medical has tie-up with Gravitas AI to market and 
                        support the AI platform – <b>“Tina”, Your Tireless AI in Australia.</b> 
                    </div>

                </div>
            </div>
            <Footer   ></Footer>
        </div>
    )
}

export default Services
