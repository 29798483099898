import HeroSection from '../HeroSection'
import './Home.css'
import './News.css'
import Footer from './Footer'
import NewsArticle from '../NewsArticle'


function News() {
    
    return (
        <div>
            <div className="ru-container">
                <HeroSection src='/images/medicine2.png'></HeroSection>
                <div className="rgn-news-content">
                    <h1>Regulatory Updates</h1>

                    <NewsArticle id="5" heading="Regulatory changes for software based medical devices"
                        description=""
                        date= "August 13, 2021" 
                        newsContent={
                        <div>
                            <p>
                                On 25 February 2021 changes to the Regulations commenced to introduce new 
                                classification rules for programmed and programmable medical devices, and 
                                medical devices that are software.  This includes all software-based medical devices.
                            </p>
                            <p>
                                The changes, which commenced on 25 February 2021, include:
                                <div className="list">
                                    <ul>
                                        <li className="item">clarifying the boundary of regulated software products (including ‘carve outs’)</li>
                                        <li className="item">introducing new classification rules</li>
                                        <li className="item">providing updates to the essential principles to more clearly express the requirements for software-based medical devices.</li>
                                    </ul>
                                </div>
                            </p>
                            <p>
                                All new applications will need to meet the new classification rules from 25 
                                February 2021, with a transition period ending 1 November 2024. If the 
                                regulatory changes result in the re-classification of a software-based 
                                medical device that is currently included in the ARTG, you are able to 
                                access transition arrangements that will allow you to continue supplying 
                                the device while you apply to include it in the ARTG under the new, higher classification.
                            </p>
                            <p>
                                For more information visit
                                <div className="list">
                                    <ul>
                                        <li className="item">
                                            <a href="https://www.legislation.gov.au/Details/F2021C00176" target="#">Therapeutic Goods (Excluded Goods) Determination 2018</a>
                                        </li>
                                        <li className="item">
                                            <a href="https://www.tga.gov.au/resource/clinical-decision-support-software" target="#">Clinical Decision Support Software</a>
                                        </li>
                                        <li className="item">
                                            <a href="https://www.tga.gov.au/resource/regulatory-changes-software-based-medical-devices" target="#">Regulatory changes for software based medical devices | Therapeutic Goods Administration (TGA)</a>
                                        </li>
                                    </ul>
                                </div>
                                 
                            </p>
                            <p>
                                The  guidance provides information about the new classification rules for 
                                software based medical devices that:

                                <div className="list">
                                    <ul>
                                        <li className="item">Provide a diagnosis or screens for a disease or condition</li>
                                        <li className="item">Monitor the state or progression of a disease or condition, or the parameters of a person with a disease or condition</li>
                                        <li className="item">Specify or recommend a treatment or intervention</li>
                                        <li className="item">Provides therapy through the provision of information</li>
                                    </ul>
                                </div>
                            </p>
                        </div>
                    }></NewsArticle>

                    <NewsArticle id="1" heading="PIC/s released a guidance on Good Practices for Data Management and integrity in a Regulated GMP / GDP environments"
                        description="Effective 1st July, 2021"
                        date= "July 21, 2021" 
                        newsContent={
                        <div>
                            <p>
                                PIC/S Participating Authorities regularly undertake inspections of manufacturers and 
                                distributors of Active Pharmaceutical Ingredient (API) and medicinal products in order to 
                                determine the level of compliance with Good Manufacturing Practice (GMP) and Good Distribution 
                                Practice (GDP) principles. These inspections are commonly performed on-site however may be 
                                performed through the remote or off-site evaluation of documentary evidence, in which case 
                                the limitations of remote review of data should be considered.

                            </p>
                            <p>
                                The effectiveness of these inspection processes is determined by the 
                                reliability of the evidence provided to the inspector and ultimately the 
                                integrity of the underlying data. It is critical to the inspection process 
                                that inspectors can determine and fully rely on the accuracy and completeness 
                                of evidence and records presented to them.
                            </p>
                            <p>
                                Data management refers to all those activities performed during the handling of 
                                data including but not limited to data policy, documentation, quality and security. 
                                Good data management practices influence the quality of all data generated and 
                                recorded by a manufacturer. These practices should ensure that data is attributable, 
                                legible, contemporaneous, original, accurate, complete, consistent, enduring, and available. 
                                While the main focus of this document is in relation to GMP/GDP expectations, the principles 
                                herein should also be considered in the wider context of good data management such as data 
                                included in the registration dossier based on which API and drug product control strategies 
                                and specifications are set. Good data management practices apply to all elements of the 
                                Pharmaceutical Quality System and the principles herein apply equally to data generated by 
                                electronic and paper-based systems.

                            </p>
                            <p>
                                Data Integrity is defined as “the degree to which data are complete, consistent, 
                                accurate, trustworthy, and reliable and that these characteristics of the data are 
                                maintained throughout the data life cycle”.1 This is a fundamental requirement for 
                                an effective Pharmaceutical Quality System which ensures that medicines are of the 
                                required quality. Poor data integrity practices and vulnerabilities undermine 
                                the quality of records and evidence, and may ultimately undermine the quality of 
                                medicinal products.

                            </p>
                            <p>
                                The responsibility for good practices regarding data management and integrity 
                                lies with the manufacturer or distributor undergoing inspection. They have 
                                full responsibility and a duty to assess their data management systems for 
                                potential vulnerabilities and take steps to design and implement good data 
                                governance practices to ensure data integrity is maintained.
                            </p>
                        </div>
                    }></NewsArticle>
                    
                   <NewsArticle id="2" heading="Health Canada issues guidance on Validation effective June 29, 2021" description={<><div>Effective June 29, 2021</div>
                            <div>Replaces: Validation Guidelines for Pharmaceutical Dosage Forms (December 1, 2009)</div></>}
                            date= "July 21, 2021" 
                            newsContent={
                            <>
                                <p>
                                    These guidelines interpret the requirements for good manufacturing practices (GMP) 
                                    in Part C, Division 2 of the Regulations. They were developed by Health Canada 
                                    in consultation with stakeholders.
                                </p>
                                <p>
                                    Guidance documents like this one are meant to help industry and health care 
                                    professionals understand how to comply with regulations. They also provide 
                                    guidance to Health Canada staff, so that the rules are enforced in a fair, 
                                    consistent and effective way across Canada. Health Canada inspects establishments 
                                    to assess their compliance with the Food and Drugs Act
                                    (the Act) and associated regulations. When we conduct an inspection, we will 
                                    use this document as a guide in assessing your compliance with GMP requirements.
                                    These guidelines are not the only way GMP regulations can be interpreted and 
                                    are not intended to cover every possible case. Other ways of complying with 
                                    GMP regulations will be considered with proper scientific justification. 
                                    Also, as new technologies emerge, different approaches may be called for.
                                </p>
                            </>
                            }></NewsArticle>

                            <NewsArticle id="3" heading="EU regulations and Directives explained:"
                                 description=""
                                 date= "July 21, 2021"
                                 newsContent={
                                 <>
                                    <p>
                                        An EU Directive/ Regulation is a form of legislation that sets out requirements 
                                        your products must meet in order to sell them into Europe.
                                    </p>
                                    <p>
                                        The European Union introduced a series of measures to simplify the movement of 
                                        goods throughout the European Union (EU) and the European Free Trade Area (EFTA). 
                                        Some of these measures are known as New Approach Directives. 
                                        New Approach Directives provide controls on product design and above all, seek to harmonize 
                                        product safety requirements across Europe.
                                    </p>
                                    <p>
                                        Throughout Europe, where one of these directives or regulations is in force, 
                                        it is necessary for the manufacturer to CE mark their product. 
                                        CE marking requirements vary from each directive/regulation and even vary 
                                        within some of them.
                                    </p>
                                    <p>
                                        Third-party testing, systems assessment and technical file assessments can be 
                                        mandatory, but sometimes the manufacturer's unverified claim is all that's 
                                        asked for. But beware! If you claim your product complies and it doesn't, you 
                                        can be prosecuted. Where there is a requirement that products and/or systems 
                                        are to be independently tested, certified, or inspected, this must be done by 
                                        a "Notified Body" or "Competent Body".
                                    </p>
                                    <p>
                                        The directives and regulations cover a very wide range of product areas 
                                        including electrical products and gas equipment amongst others. 
                                        Their primary objective is to ensure that the products are well designed, 
                                        and safe for the user.
                                    </p>
                                </>
                            }></NewsArticle>
                    
                            <NewsArticle id="4" heading="Guidance on Regulating medical devices in the UK"
                                description="" 
                                date= "July 21, 2021" 
                                newsContent={
                                    <>
                                        <p>
                                            We will continue to accept CE marked devices on the Great Britain market 
                                            until 30 June 2023. This applies to devices that have been CE marked under 
                                            and fully conform with the following applicable EU legislation:
                                        </p>
                        
                        
                                        <div className="list">
                                            <ul>
                                                <li className="item">Directive 90/385/EEC on active implantable medical devices (EU AIMDD)</li>
                                                <li className="item">Directive 93/42/EEC on medical devices (EU MDD)</li>
                                                <li className="item">Directive 98/79/EC on in vitro diagnostic medical devices (EU IVDD)</li>
                                                <li className="item">Regulation 2017/745 on medical devices (EU MDR)</li>
                                                <li className="item">Regulation 2017/746 on in vitro diagnostic medical devices (EU IVDR)</li>
                                            </ul>
                                        </div>
                                        <p>
                                            From 1 July 2023, new devices placed on the Great Britain market will need to 
                                            conform with UKCA marking requirements.
                                        </p>
                                        <p>
                                            If you currently CE mark your medical device on the basis of self-certification, you will be 
                                            able to continue to do so and place your device on the Great Britain market until 30 June 2023. 
                                        </p>
                                        <p>
                                            The UKCA (UK Conformity Assessed) marking is a new UK product marking that is 
                                            used for goods being placed on the market in Great Britain (England, Wales 
                                            and Scotland). It covers most goods which previously required the CE marking, 
                                            known as ‘new approach’ goods.
                                        </p>
                                        <p>
                                            Medical devices placed on the Great Britain market must have a UKCA mark or 
                                            a CE marking, depending on which legislation the device has been certified under.
                                        </p>
                                        <p>
                                            Where relevant, the number of the Notified Body or Approved Body must also appear on 
                                            the label.
                                        </p>
                                        <p>
                                            If you already have a valid CE marking on your device, you are not required to 
                                            re-label the device with a UKCA mark until 1 July 2023 for placement on the 
                                            Great Britain market. Devices can have both marks present on the labelling 
                                            prior to 1 July 2023, and dual marking will continue to be accepted on the 
                                            Great Britain market after 1 July 2023. However, the name and address of the 
                                            UK Responsible Person, where applicable, needs to be included on product 
                                            labelling where the UKCA mark has been affixed (including when devices have 
                                            been dual marked).
                                        </p>
                                    </>
                                }></NewsArticle>
                    
                </div>
                <Footer></Footer>
            </div>
        </div>
    )
}

export default News
